<template>
  <transition>
    <div class="modal" v-if="finishHydrating">
      <div
        v-if="visible"
        class="modal__overlay"
        :class="{
          'modal--overlay': overlay,
          'modal--overlay-dark': overlay && dark,
        }"
        @click="close"
        v-body-scroll-lock="visible"
      ></div>
      <div
        v-show="visible"
        :data-modal-active="visible ? true : false"
        class="modal__container"
      >
        <div class="modal__inner container">
          <div v-if="cross" class="modal__close" @click="close">
            <div class="icon-cross"></div>
          </div>

          <div
            ref="content"
            class="modal__content"
            :class="{ 'modal__content--scroll': !hasNestedModal }"
          >
            <!--@slot Use this slot to place content inside the modal.-->
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();
const app = useNuxtApp();
const state = useStates();
const { finishHydrating } = checkHydration();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  closeButton: {
    type: Boolean,
    default: true,
  },
  /**
   * Visibility of the modal
   */
  visible: {
    type: Boolean,
    default: false,
  },
  /**
   * Cross closing modal button
   */
  cross: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether to show the overlay
   */
  overlay: {
    type: Boolean,
    default: true,
  },
  /**
   * If true clicking outside will not dismiss the modal
   */
  persistent: {
    type: Boolean,
    default: false,
  },
  /**
   * overlay transition effect
   */
  transitionModal: {
    type: String,
    default: "sf-fade",
  },
  ignoreModalState: {
    type: Boolean,
    default: false,
  },
  dark: {
    type: Boolean,
    default: false,
  },
});

const staticClass = ref(null);
const className = ref(null);
const hasNestedModal = ref(false);

let content = ref(null);

const emit = defineEmits(["close"]);

const close = () => {
  emit("close", false);
};
const checkPersistence = () => {
  if (!props.persistent) {
    close();
  }
};
const keydownHandler = (e) => {
  if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
    close();
  }
};

onMounted(() => {
  app.$bus.$on("checkNestingModals", () => {
    if (
      content.value &&
      content.value.querySelector("[data-modal-active='true']")
    ) {
      hasNestedModal.value = true;
    }
  });
});

watch(
  () => props.visible,
  (value) => {
    if (value) {
      nextTick(() => {
        app.$bus.$emit("checkNestingModals");
      });
      document.addEventListener("keydown", keydownHandler);
      state.setModalOpen(true);
    } else {
      // clearAllBodyScrollLocks()
      document.removeEventListener("keydown", keydownHandler);
      state.setModalOpen(false);
    }
  }
);
</script>

<style lang="scss">
.modal {
  // &--overlay {
  //   background-color: rgba($color-black, 0.5);
  // }
  // &--overlay-dark {
  //   background-color: rgba($color-black, 0.97);
  // }
}

.modal__overlay {
  position: fixed;
  inset: 0;
  background-color: $color-black;
  opacity: 0.8;
  z-index: 50;
}

.modal__container {
  position: fixed;
  inset: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-base;
  pointer-events: none;
}

.modal__inner {
  padding: $spacing-base;
  background-color: $color-white;
  position: relative;
  border-radius: $radius-small;
  min-width: 100%;
  pointer-events: all;

  // @media (min-width: $xs) {
  //   min-width: 400px;
  // }

  @media (min-width: $md) {
    min-width: 500px;
  }
}

.modal__close {
  position: absolute;
  right: 0.5rem;
  bottom: calc(100% + 0.5rem);
  font-size: $text-xl;
  background: $color-white;
  color: $color-dark-gray;
  // z-index: 50;
  border-radius: 50%;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;

  & > div {
    @include absolute-center;
  }
}

.modal__content {
  max-height: 80vh;
  width: 100%;

  @media (min-width: $md) {
    max-width: 36rem;
  }

  &--scroll {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal__cancel {
  position: sticky;
  bottom: 0;
  background-color: $color-white;
  padding: $spacing-base 0;
  border-top-width: 1px;
  border-color: $color-gray;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
